<script>
import TeacherMainLayout from "../../layouts/teacher-view-info.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";


export default {
  page: {
    title: "ดุโปรไฟล์",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    TeacherMainLayout,
    PageHeader,
  },
  data() {
    return {
      title: "ระบบหลัก",
      items: [
        {
          text: "Thaimed",
        },
        {
          text: "ดุโปรไฟล์",
          active: true,
        },
      ],
      currentUser: {},
      attach: this.$route.params.attach,
    };
  },
  methods: {
    getCurrentUser() {
      const user = JSON.parse(localStorage.getItem('auth.currentUser')) || {};
      this.currentUser = user;
    },
    getLatestUserInfo() {
      const user = JSON.parse(localStorage.getItem('auth.currentUser')) || {};

      if (user == {}) {
        return;
      }

      return (
        this.$store.dispatch("apiRestful/getLatestUserInfo", {
          uid: user.uid,
        })
          // eslint-disable-next-line no-unused-vars
          .then((resp) => {
            console.log("resp:", resp);
            if (resp) {
              localStorage.setItem('auth.currentUser', JSON.stringify(resp));
              this.currentUser = resp;
            }
          })
          .catch((error) => {
            console.log("error:", error);
          })
      );

    },
  },
  beforeMount() {
    this.getLatestUserInfo();
    this.getCurrentUser();
    console.log("getCurrentUser:", this.currentUser);
  },
  mounted() {
    console.log("this.attach:", this.attach);
  },
};
</script>

<template>
  <TeacherMainLayout>
    <PageHeader :title="title" :items="items"/>
  </TeacherMainLayout>
</template>