<script>
import {
    teacherMainLayoutMethods,
    teacherMainLayoutComputed,
} from "@/state/helpers";
import Horizontal from "./horizontal";

/**
 * Main Layout
 */
export default {
    components: {
        Horizontal,
    },
    data() {
        return {
            currentUser: this.$parent.currentUser,
        };
    },
    computed: {
        ...teacherMainLayoutComputed,
    },
    mounted() {
        document.body.classList.add('authentication-bg')
    },
    methods: {
        ...teacherMainLayoutMethods,
    },
};
</script>

<template>
    <div>
        <Horizontal :layout="layoutType">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body text-center">
                            <h4>ข้อมูล</h4>
                        </div>
                    </div>
                </div>
            </div>
        </Horizontal>
    </div>
</template>
